<template>
  <v-dialog persistent v-model="isEnableTransponderForm" width="40%">
    <v-form ref="product_form" autocomplete="off">
      <v-card>
        <v-card-text class="border-bottom mb-3">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon
                    class="text-2xl font-semibold"
                    text="Add Transponder"
                    style="color: black"
                >
                </SvgIcon>
                <v-btn fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <v-row dense justify="left" class="mt-6">
            <v-row>
              <v-col cols="3" md="3" sm="12" class="pb-0">
                <label for=""> Transponder Label </label>
                <v-text-field
                    outlined
                    background-color="#fff"
                    light
                    v-model="transponder.label"
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                    required
                    :rules="[(v) => !!v || 'Tag name is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="3" md="3" sm="12">
                <label for=""> Color </label>
                <v-select
                    v-model="transponder.color"
                    :items="transponderColors"
                    item-text="name"
                    item-value="id"
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    outlined
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                    required
                ></v-select>
              </v-col>

              <v-col cols="3" md="3" sm="12">
                <label for=""> Transponder Type </label>
                <v-select
                    v-model="transponder.transponder_type"
                    :items="transponderCategories"
                    item-text="name"
                    item-value="id"
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    outlined
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                    required
                ></v-select>
              </v-col>
              <v-col md="3" class="pl-0 mt-5">
                <v-switch
                    class="mx-4 my-0"
                    dense
                    hide-details="auto"
                    :false-value="2"
                    :true-value="1"
                    label="Active"
                    required
                    v-model="transponder.status_id"
                ></v-switch>
              </v-col>
            </v-row>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close()" class="ma-2">Close</v-btn>
          <v-btn text class="ma-2 white--text blue-color" @click="saveTransponder"
          >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  name: "TransponderForm",
  components: {SvgIcon},
  props: {
    isEnableTransponderForm:{type:Boolean, default: false},
    id:{ type:Number, default: null},
    venue_service_id:{ type:Number, default: null},
  },
  watch: {
    isEnableTransponderForm:{
      immediate: true,
      handler(val){
        if(val && this.id){
          this.getTransponder(this.id);
        }
      }
    }
  },
  methods: {
    getTransponder(id){
      this.showLoader();
      this.$http
          .get(
              `venues/facilities/transponders/${id}`
          )
          .then((response) => {
            if (response.status == 200) {
              this.transponder = response.data.data;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
        this.hideLoader()
      });
    },
    saveTransponder(){
      this.showLoader();
      let data = {
        id:this.transponder.id,
        label:this.transponder.label,
        color:this.transponder.color,
        transponder_type:this.transponder.transponder_type,
        status_id:this.transponder.status_id,
        venue_service_id:this.venue_service_id,
      }
      this.$http
          .post(
              `venues/facilities/transponders/store-transponder`, data
          )
          .then((response) => {
            if (response.status == 200) {
              this.close();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
        this.hideLoader()
      });
    },
    close() {
      this.$emit("close");
    }
  },
  data(){
    return {
      transponderCategories: [
        {
          id: 0,
          name: 'Unavailable',
        },
        {
          id: 1,
          name: 'TranX2',
        },
        {
          id: 2,
          name: 'CarBike',
        },
        {
          id: 4,
          name: 'X2',
        },
        {
          id: 6,
          name: 'ProChip',
        },
        {
          id: 7,
          name: 'MX',
        },
        {
          id: 10,
          name: 'CarBikeDP',
        },
        {
          id: 11,
          name: 'RentalKart',
        },
        {
          id: 12,
          name: 'Kart',
        },
        {
          id: 13,
          name: 'TranXPro',
        },
        {
          id: 14,
          name: 'ProChipFlex',
        },
      ],
      transponderColors: [
        {
          id: 0,
          name: 'White',
        },
        {
          id: 1,
          name: 'Black',
        },
        {
          id: 2,
          name: 'Yellow',
        },
        {
          id: 3,
          name: 'Orange',
        },
        {
          id: 4,
          name: 'Red',
        },
        {
          id: 5,
          name: 'Green',
        },
        {
          id: 6,
          name: 'Blue',
        },
        {
          id: 7,
          name: 'Purple',
        },
      ],
      transponder:{
        id:null,
        label:'',
        color:0,
        transponder_type:0,
        status_id:1,
      }
    }
  },
}
</script>


<style scoped>

</style>