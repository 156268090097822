<template>
  <v-container fluid>
    <FacilitiesTabs
        :configurations="configurations"
        :show-add-category="false"
        :venue-service-id="venueServiceId"
        :venue-services="venueServices"
        @serviceChange="serviceChange"
        @update:venueServiceId="(value) => (venueServiceId = value)"
    />

    <v-divider
        class="mt-4"
        style="border-color: rgba(17, 42, 70, 0.14) !important"
    />

    <v-row>
        <v-col v-for="(transponder,index) in transponders" :key="index" lg="3" md="3" xl="3">
            <TransponderWidget
                :key="index"
                :id="transponder.id"
                :color="getTransponderColorName(transponder.color)"
                :transponder_type="getTransponderTypeName(transponder.transponder_type)"
                :index="index"
                :label="transponder.label"
                :status_id="transponder.status_id"
                @edit="editTransponder"
                @delete="deleteTransponder"
                @toggleTransponderStatus="toggleTransponderStatus"
            ></TransponderWidget>
        </v-col>
    </v-row>




    <v-row class="mt-8 ma-15 pa-10" v-if="1 ==2">
      <v-col cols="12" lg="12" md="12">
        <v-form ref="cform">
          <div class="titles">Transponders</div>
          <v-row v-for="(transponder,index) in transponders" :key="index">
            <v-col lg="12" md="6" sm="12" cols="12" style="position: relative">
              <v-card class="shadow-2">
                <v-container>
                  <v-row>
                    <v-col cols="3" md="3" sm="12" class="pb-0">
                      <label for=""> Transponder Label </label>
                      <v-text-field
                          outlined
                          background-color="#fff"
                          light
                          v-model="transponder.label"
                          dense
                          hide-details="auto"
                          class="q-text-field shadow-0"
                          required
                          :rules="[(v) => !!v || 'Tag name is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3" md="3" sm="12">
                      <label for=""> Color </label>
                      <v-select
                          v-model="transponder.color"
                          :items="transponderColors"
                          item-text="name"
                          item-value="id"
                          :menu-props="{ bottom: true, offsetY: true }"
                          background-color="#fff"
                          outlined
                          dense
                          hide-details="auto"
                          class="q-text-field shadow-0"
                          required
                      ></v-select>
                    </v-col>

                    <v-col cols="3" md="3" sm="12">
                      <label for=""> Transponder Type </label>
                      <v-select
                          v-model="transponder.transponder_type"
                          :items="transponderCategories"
                          item-text="name"
                          item-value="id"
                          :menu-props="{ bottom: true, offsetY: true }"
                          background-color="#fff"
                          outlined
                          dense
                          hide-details="auto"
                          class="q-text-field shadow-0"
                          required
                      ></v-select>
                    </v-col>
                    <v-col md="3" class="pl-0 mt-5">
                      <v-switch
                          class="mx-4 my-0"
                          dense
                          hide-details="auto"
                          :false-value="2"
                          :true-value="1"
                          label="Active"
                          required
                          v-model="transponder.status_id"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-if="transponders.length > 1"
                      color="red"
                      dark
                      elevation="0"
                      fab
                      v-bind="attrs"
                      x-small
                      absolute
                      top
                      style="top: -5px"
                      right
                      @click="deleteTransponder(index)"
                      v-on="on"
                  >
                    <DeleteIcon/>
                  </v-btn>
                </template>
                Delete
              </v-tooltip>
            </v-col>
          </v-row>
          <div class="add_btn mt-2" style="text-align: left">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="#00AAAAFF"
                    dark
                    @click="addTransponder()"
                    style="text-transform: none"
                >
                  + Add Transponder
                </v-btn>
              </template>
              + Add new transponder
            </v-tooltip>
          </div>

          <div style="float: right">
            <v-btn
                height="40"
                width="100"
                class="white--text blue-color"
                @click="saveTransponder"
            >Save
            </v-btn>
          </div>
        </v-form>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <div class="add_btn mt-2" style="text-align: left">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              color="#00AAAAFF"
              dark
              @click="addTransponder()"
              style="text-transform: none"
          >
            + Add Transponder
          </v-btn>
        </template>
        + Add new transponder
      </v-tooltip>
    </div>

    <TransponderForm
      :id="editTransponderId"
      @close="closeTransponderForm"
      :venue_service_id="venueServiceId"
      :is-enable-transponder-form="isEnableTransponderForm"
    />
    <confirm-model
        v-bind="confirmModel"
        @close="confirmModel.id = null"
        @confirm="confirmActions"
    ></confirm-model>
  </v-container>
</template>

<script>
import DeleteIcon from "@/assets/images/retail/delete-bg-icon.svg";
import FacilitiesTabs from "@/views/Facility/FacilitiesTabs.vue";
import TransponderWidget from "@/components/Facility/TransponderWidget.vue";
import TransponderForm from "@/components/Facility/TransponderForm.vue";

export default {
  components: {
    TransponderForm,
    TransponderWidget,
    FacilitiesTabs,
    DeleteIcon,
  },

  data: () => ({
    editTransponderId:null,
    isEnableTransponderForm:false,
    selectedCategory: null,
    categoryId: 0,
    venueServiceId: null,
    transponders: [],
    transponderCategories: [
      {
        id: 0,
        name: 'Unavailable',
      },
      {
        id: 1,
        name: 'TranX2',
      },
      {
        id: 2,
        name: 'CarBike',
      },
      {
        id: 4,
        name: 'X2',
      },
      {
        id: 6,
        name: 'ProChip',
      },
      {
        id: 7,
        name: 'MX',
      },
      {
        id: 10,
        name: 'CarBikeDP',
      },
      {
        id: 11,
        name: 'RentalKart',
      },
      {
        id: 12,
        name: 'Kart',
      },
      {
        id: 13,
        name: 'TranXPro',
      },
      {
        id: 14,
        name: 'ProChipFlex',
      },
    ],
    transponderColors: [
      {
        id: 0,
        name: 'White',
      },
      {
        id: 1,
        name: 'Black',
      },
      {
        id: 2,
        name: 'Yellow',
      },
      {
        id: 3,
        name: 'Orange',
      },
      {
        id: 4,
        name: 'Red',
      },
      {
        id: 5,
        name: 'Green',
      },
      {
        id: 6,
        name: 'Blue',
      },
      {
        id: 7,
        name: 'Purple',
      },
    ],

    confirmModel: {
      id: null,
      title: null,
      description: null,
    },
    valid: false,
    deletedTransponder: [],
    configurations: {},
  }),

  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices").then((res) => {
        if (res.length) {
          this.checkVenueService();
        }
      });
    } else {
      this.checkVenueService();
    }

  },
  computed: {
    venueServices() {
      return this.$store.getters.getSportsService;
    },
  },
  methods: {
    toggleTransponderStatus(data){
      this.showLoader();
      this.$http
          .get(
              `venues/facilities/transponders/toggle/${data.id}`
          )
          .then((response) => {
            if (response.status == 200) {
              this.getTransponders();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
        this.hideLoader()
      });
    },
    closeTransponderForm(){
      this.isEnableTransponderForm = false;
      this.editTransponderId = null;
      this.getTransponders();
    },
    addTransponder(id = null) {
      this.isEnableTransponderForm = true;
      if(id){
        this.editTransponderId = id;
      }
    },
    editTransponder(data){
      this.editTransponderId = data.id;
      this.isEnableTransponderForm = true;
    },
    deleteTransponder(data){
      this.$http
          .get(
              `venues/facilities/transponders/delete/${data.id}`
          )
          .then((response) => {
            if (response.status == 200) {
              this.getTransponders();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
        this.hideLoader()
      });
    },
    getTransponderTypeName(id){
      const foundItem = this.transponderCategories.find((item) => item.id == id);
      return foundItem ? foundItem.name : '';
    },
    getTransponderColorName(id){
      const foundItem = this.transponderColors.find((item) => item.id == id);
      return foundItem ? foundItem.name : '';
    },
    getTransponders() {
      this.showLoader();
      this.$http
          .get(
              `venues/facilities/transponders?venue_service_id=${this.venueServiceId}`
          )
          .then((response) => {
            if (response.status == 200) {
              this.transponders = response.data.data;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
        this.hideLoader()
      });
    },
    saveTransponder() {
      if (!this.$refs.cform.validate()) {
        this.showError("Please fill all required fields");
        return;
      }

      let formData = new FormData();

      formData.append(`venue_service_id`, this.venueServiceId);

      if (this.deletedTransponder.length) {
        this.deletedTransponder.forEach((id, index) => {
          formData.append(`deleted_transponders[${index}]`, id);
        });
      }

      this.transponders.forEach((transponder, index) => {
        if(transponder.id){
          formData.append(`transponders[${index}][id]`, transponder.id);
        }
        formData.append(`transponders[${index}][label]`, transponder.label);
        formData.append(`transponders[${index}][color]`, transponder.color);
        formData.append(`transponders[${index}][transponder_type]`, transponder.transponder_type);
        formData.append(`transponders[${index}][status_id]`, transponder.status_id);
      });
      this.showLoader('Saving..');
      this.$http
          .post(
              `venues/facilities/transponders`,
              formData
          )
          .then((response) => {
            if (response.status == 200) {
              this.getTransponders();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          }).finally(() => {
        this.hideLoader()
      });
    },
    // deleteTransponder(index) {
    //   this.confirmModel = {
    //     id: index,
    //     title: "Do you want to delete this transponder?",
    //     description:
    //         "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
    //     type: "delete",
    //   };
    // },
    confirmActions(data) {
      if (data.type == "delete") {
        let transponder = this.transponders[data.id];
        if (transponder.id) {
          this.deletedTransponder.push(transponder.id);
        }
        this.transponders.splice(data.id, 1);
        if (this.transponders.length == 0) {
          this.transponders = [{}];
        }
      }
      this.confirmModel.id = null;
    },
    serviceChange() {
      this.initializeFacilityServices();
    },
    checkVenueService() {
      if (this.$route.params.data != null) {
        let data = JSON.parse(atob(this.$route.params.data));
        this.venueServiceId = data.venue_service_id;
      } else {
        this.venueServiceId =
            this.$store.getters.getSportsService[0].venue_service_id;
      }
      setTimeout(() => {
        this.initializeFacilityServices();
        this.getTransponders();
      }, 10);
    },
    initializeFacilityServices() {
      if (!this.$store.getters.getConfigurationStatus(this.venueServiceId)) {
        this.$store
            .dispatch("loadConfigurationsByVenueServiceId", this.venueServiceId)
            .then((response) => {
              if (response.status == 200) {
                let data = this.$store.getters.getConfigurationByVenueServiceId(
                    this.venueServiceId
                );
                this.configurations = data;
                if (this.configurations.is_my_lap_enabled != 1) {
                  this.gotoFacilities();
                }
                this.$forceUpdate();
              }
            });
      } else {
        let data = this.$store.getters.getConfigurationByVenueServiceId(
            this.venueServiceId
        );
        this.configurations = data;
        if (this.configurations.is_my_lap_enabled != 1) {
          this.gotoFacilities();
        }
        this.$forceUpdate();
      }
      this.$forceUpdate();
    },


    deleteProduct(productId) {
      // const searchProductById = this.productCategories
      //         .flatMap(category => category.products)
      //         .find(product => product.product_id === productId);
      this.confirmModel = {
        id: productId,
        title: "Do you want to delete this product?",
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete_product",
      };
    },

    gotoFacilities() {
      this.$router.push({
        name: "FacilityPerService",
        params: {
          data: btoa(JSON.stringify({venue_service_id: this.venueServiceId})),
        },
      });
    },

    gotoConfiguration() {
      if (!this.venueServiceId) {
        this.showError("Please add new service");
        return;
      }
      this.$router.push({
        name: "FacilityConfiguration",
        params: {
          data: btoa(JSON.stringify({venue_service_id: this.venueServiceId})),
        },
      });
    },
    productClose() {
      this.productDialog = false;
      this.productData = {};
    },
    productSave() {
      this.productDialog = false;
      this.productData = {};
      this.getProductAndCategory();
    },
    deleteCategory(index) {
      this.productCategories.splice(index, 1);
      if (!this.productCategories.length) {
        this.productCategories = [];
      }
    },
    gotoRentalTicketProducts(currentSlide) {
      if (!this.venueServiceId) {
        this.showError("Please add new service");
        return;
      }
      this.$router.push({
        name: "TickerRentalProductPerService",
        params: {
          data: btoa(
              JSON.stringify({
                venue_service_id: this.venueServiceId,
                is_ticket: currentSlide == "ticket" ? 1 : 0,
              })
          ),
        },
      });
    },
  },
};
</script>
<style scoped>
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}

.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}

.v-expansion-panel-header--active {
  color: #00b0af;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
}

.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: none;
}

.month_names {
  background-color: rgb(1, 78, 97);
  padding: 6px 8px;
  border-radius: 5px;
  color: white;
}

.v-expansion-panel-header--active .month_names {
  background-color: rgb(4, 148, 184);
}

.tab_is_active {
  color: #112a46;
  font-weight: 600;

  svg {
    opacity: 1 !important;
  }
}

.q-tab-nav {
  svg {
    fill: none !important;
    stroke: black !important;
    opacity: 0.4;
    min-width: 20px;
  }
}

.active_category {
  background-color: rgba(79, 174, 175, 0.1) !important;
  color: rgba(79, 174, 175, 1) !important;
  border: 1px solid rgba(79, 174, 175, 1) !important;
}
</style>
