var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"shadow product_card mt-5",staticStyle:{"border-radius":"8px"}},[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',{staticClass:"font-semibold text-lg black--text m-b-4 mt-3 ml-2"},[_c('span',{staticClass:"line-clamp-1"},[_vm._v(_vm._s(_vm.label))])]),_c('div',{staticClass:"align-items-center",staticStyle:{"margin-top":"-11px"}},[_c('LightningBoltIcon',{staticClass:"pointer",class:_vm.status_id == 1 ? '' : 'fill-red',staticStyle:{"margin-right":"10px"},on:{"click":function($event){$event.stopPropagation();return _vm.enableDisableTransponder($event)}}}),_c('v-menu',{attrs:{"content-class":"q-menu","right":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('DotsIcon',_vm._g(_vm._b({},'DotsIcon',attrs,false),on))]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$emit('edit', {
                        index: _vm.index,
                        id: _vm.id,
                      })}}},[_vm._v(" Edit ")]),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('delete', {
                        index: _vm.index,
                        id: _vm.id,
                      })}}},[_vm._v(" Delete ")])],1)],1)],1)]),_c('p',{staticClass:"font-medium m-1 p-0 ml-2"},[_c('span',{staticClass:"text-elepsis"},[_vm._v("Color: "+_vm._s(_vm.color))])]),_c('p',{staticClass:"font-medium m-1 p-0 ml-2"},[_c('span',{staticClass:"text-elepsis"},[_vm._v("Type: "+_vm._s(_vm.transponder_type))])])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }